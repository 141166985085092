<template>
  <div class="content">
    <el-card class="box-card">
      <div class="Untreated_top" slot="header">
        <span class="top_title">{{$t('客户站点管理')}}</span>
        <div>
          <el-button type="primary" @click='dialogForm' v-if="user_role['region/add']" size="small">{{$t('新增站点')}}</el-button>
          <el-button type="primary" size="small" @click="getUserList(Page.page,1)">{{$t('导出EXCEL')}}</el-button>
        </div>
      </div>
      <div style="margin:15px 0;">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input :placeholder="$t('请输入关键字')" clearable v-model="search" class="input-with-select"></el-input>
          </el-col>
          <el-col :span="6">
            <el-select v-model="ocid" clearable :placeholder="$t('请选择客户')">
                <el-option
                  v-for="item in ocList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="6">
            <el-select v-model="warranty_type" clearable :placeholder="$t('请选择质保类型')">
                <el-option
                  v-for="item in warranty_type_list"
                  :key="item.value"
                  :label="item.text"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="6">
            <el-button icon="el-icon-search" @click="getUserList(1)"></el-button>
          </el-col>
        </el-row>

      </div>
      <el-table :data="UntreatedData" height="500" style="width: 100%" border :row-class-name="tableRowClassName">
        <el-table-column :label="$t('ID')" prop="id" fixed="left" width="100" align="center" header-align="left">
        </el-table-column>
        <el-table-column :label="$t('客户名称')" prop="kehu_name" width="200">
        </el-table-column>
        <el-table-column :label="$t('站点名称')" prop="rname" width="200">
        </el-table-column>
        <el-table-column :label="$t('联系方式')" prop="rtel" width="160">
        </el-table-column>
       <!-- <el-table-column label="质保类型" prop="warranty_type" width="100">
        </el-table-column> -->
        <el-table-column :label="$t('站点地址')" prop="address" width="300">
        </el-table-column>
        <el-table-column :label="$t('质保期限')" prop="warranty_period" width="100">
        </el-table-column>
        <el-table-column :label="$t('质保起始日期')" prop="warranty_start_time" width="300">
        </el-table-column>
        <el-table-column :label="$t('质保类型')" width="200">
           <template slot-scope="scope">
              <span>{{getType(scope.row.warranty_type)}}</span>
           </template>
        </el-table-column>
        <el-table-column :label="$t('经度')" prop="lng" width="150">
        </el-table-column>
        <el-table-column :label="$t('纬度')" prop="lat" width="150">
        </el-table-column>
        <el-table-column :label="$t('创建时间')" prop="created_at" width="200">
        </el-table-column>
        <el-table-column fixed="right" :label="$t('操作')" align="center" header-align="left" width="250">
          <template slot-scope="scope">
           <!-- <el-button type="text" size="small" @click="getDetail(scope)">查看详情</el-button> -->
            <el-button type="text" @click='dialogForm(scope,true)' v-if="user_role['region/edit']" size="small">{{$t('修改站点')}}</el-button>
            <el-button type="text" @click="delRole(scope)" v-if="user_role['region/delete']" size="small">{{$t('删除站点')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="Page.page"
        :page-size="Page.pagesize" layout="total, prev, pager, next, jumper" :total="Page.total"
        style="text-align: center;">
      </el-pagination>
    </el-card>
    <el-dialog :title="$t('添加站点')" :visible.sync="addAdmin" width="80%">
      <el-row :gutter="20">
        <el-form :model="addform" label-position="right" label-width="100px" ref="addform">
          <el-col :span="12">
            <el-form-item :label="$t('客户ID')" prop="oc_id"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-select v-model="addform.oc_id" clearable :placeholder="$t('请选择')">
                  <el-option
                    v-for="item in ocList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col><!-- /客户ID -->
          <el-col :span="12">
            <el-form-item :label="$t('站点名称')" prop="rname"
            :rules="[{ required: true, message: $t('不能为空')}]">
              <el-input v-model="addform.rname" clearable></el-input>
            </el-form-item>
          </el-col><!-- /区域名称 -->
          <el-col :span="12">
            <el-form-item :label="$t('联系电话')" prop="rtel">
              <el-input v-model="addform.rtel" clearable></el-input>
            </el-form-item>
          </el-col><!-- /区域联系电话 -->
          <el-col :span="12">
            <el-form-item :label="$t('详细地址')" prop="address">
              <el-input v-model="addform.address" @blur="getCode" id="address"></el-input>
            </el-form-item>
          </el-col><!-- /详细地址 -->
          <!-- <el-col :span="12">
            <el-form-item label="经度" required prop="lng">
              <el-input v-model="addform.lng" id="lng"></el-input>
            </el-form-item>
          </el-col> --><!-- /经纬度 -->
          <!-- <el-col :span="12">
            <el-form-item label="纬度" required prop="lat">
              <el-input v-model="addform.lat" id="lat"></el-input>
            </el-form-item>
          </el-col> --><!-- /经纬度 -->
          <el-col :span="12">
            <el-form-item :label="$t('起保时间')" prop="warranty_start_time">
              <el-date-picker
                    v-model="addform.warranty_start_time"
                    type="datetime"
                    :placeholder="$t('选择日期时间')">
                  </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('质保年限')" prop="warranty_period">
              <el-input v-model="addform.warranty_period" type="number"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('续保类型')" prop="renewal_type">
              <el-select v-model="addform.renewal_type" clearable :placeholder="$t('请选择')">
                  <el-option :label="$t('未续保')" :value="0"></el-option>
                  <el-option :label="$t('按次续保')" :value="1"></el-option>
                  <el-option :label="$t('包年续保')" :value="2"></el-option>
                </el-select>
            </el-form-item>
          </el-col><!-- /续保类型 -->
          <el-col :span="12">
            <el-form-item :label="$t('续保时间')" prop="renewal_start_time">
              <el-date-picker
                    v-model="addform.renewal_start_time"
                    type="datetime"
                    :placeholder="$t('选择日期时间')">
                  </el-date-picker>
            </el-form-item>
          </el-col><!-- 续保 -->
          <el-col :span="12">
            <el-form-item :label="$t('续保年限')" prop="renewal_period">
              <el-input v-model="addform.renewal_period" type="number" min="0"></el-input>
            </el-form-item>
          </el-col><!-- 续保年限 -->
          <el-col :span="24">
            <el-form-item :label="$t('备注信息')" prop="remarks">
              <el-input v-model="addform.remarks" type="textarea" :rows="5" maxlength="50" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdmin = false">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="addMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog :title="$t('修改站点信息')" :visible.sync="editAdmin" width="80%">
      <el-row :gutter="20">
       <el-form :model="editform" label-position="left" label-width="100px" ref="editform">
         <el-col :span="12">
           <el-form-item :label="$t('客户ID')" prop="oc_id"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-select v-model="editform.oc_id" clearable :placeholder="$t('请选择')">
                 <el-option
                   v-for="item in ocList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
                 </el-option>
               </el-select>
           </el-form-item>
         </el-col><!-- /客户ID -->
         <el-col :span="12">
           <el-form-item :label="$t('站点名称')" prop="rname"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-input v-model="editform.rname" clearable></el-input>
           </el-form-item>
         </el-col><!-- /区域名称 -->
         <el-col :span="12">
           <el-form-item :label="$t('联系电话')" prop="rtel">
             <el-input v-model="editform.rtel" clearable></el-input>
           </el-form-item>
         </el-col><!-- /区域联系电话 -->
         <el-col :span="12">
           <el-form-item :label="$t('详细地址')" prop="address"
             :rules="[{ required: true, message: $t('不能为空')}]">
             <el-input v-model="editform.address" @blur="getCode" id="address"></el-input>
           </el-form-item>
         </el-col><!-- /详细地址 -->
         <el-col :span="12">
           <el-form-item :label="$t('起保时间')" prop="warranty_start_time"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-date-picker
                   v-model="editform.warranty_start_time"
                   type="datetime"
                   :placeholder="$t('选择日期时间')">
                 </el-date-picker>
           </el-form-item>
         </el-col><!-- 起保 -->
         <el-col :span="12">
           <el-form-item :label="$t('质保年限')" prop="warranty_period"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-input v-model="editform.warranty_period" type="number"></el-input>
           </el-form-item>
         </el-col><!-- 质保年限 -->
         <el-col :span="12">
           <el-form-item :label="$t('续保类型')" prop="renewal_type"
           :rules="[{ required: true, message: $t('不能为空')}]">
             <el-select v-model="editform.renewal_type" clearable :placeholder="$t('请选择')">
                 <el-option :label="$t('未续保')" :value="0"></el-option>
                 <el-option :label="$t('按次续保')" :value="1"></el-option>
                 <el-option :label="$t('包年续保')" :value="2"></el-option>
               </el-select>
           </el-form-item>
         </el-col><!-- /续保类型 -->
         <el-col :span="12">
           <el-form-item :label="$t('续保时间')" prop="renewal_start_time">
             <el-date-picker
                   v-model="editform.renewal_start_time"
                   type="datetime"
                   :placeholder="$t('选择日期时间')">
                 </el-date-picker>
           </el-form-item>
         </el-col><!-- 续保 -->
         <el-col :span="12">
           <el-form-item :label="$t('续保年限')" prop="renewal_period">
             <el-input v-model="editform.renewal_period" type="number"></el-input>
           </el-form-item>
         </el-col><!-- 续保年限 -->
         <el-col :span="24">
           <el-form-item :label="$t('备注信息')" prop="remarks">
             <el-input v-model="editform.remarks" type="textarea" :rows="5" maxlength="50" show-word-limit></el-input>
           </el-form-item>
         </el-col>
       </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeEdit">{{$t('取消')}}</el-button>
        <el-button type="primary" @click="editMin">{{$t('确定')}}</el-button>
      </div>
    </el-dialog>
    <!-- 详情弹窗并没有制作 -->
    <el-dialog title="站点详情" :visible.sync="Detail" width="80%">
      <el-row :gutter="20">
        <el-form v-model="DetailForm" label-position="left" label-width="80px" ref="DetailForm">
          <el-col :span="12">
            <el-form-item label="客户ID" prop="id">
              <el-input v-model="DetailForm.id" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户 -->
          <el-col :span="12">
            <el-form-item label="客户名称" prop="kehu_name">
              <el-input v-model="DetailForm.kehu_name" disabled></el-input>
            </el-form-item>
          </el-col><!-- /客户 -->
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="DetailForm.phone" disabled></el-input>
            </el-form-item>
          </el-col><!-- /手机号 -->
          <el-col :span="12">
            <el-form-item label="编号" prop="number">
              <el-input v-model="DetailForm.number" disabled></el-input>
            </el-form-item>
          </el-col><!-- /编号 -->
          <el-col :span="12">
            <el-form-item label="地区" prop="region">
              <el-input v-model="DetailForm.region" disabled></el-input>
            </el-form-item>
          </el-col><!-- /地区 -->
          <el-col :span="12">
            <el-form-item label="详细地址" prop="detailed_address">
              <el-input v-model="DetailForm.detailed_address" disabled></el-input>
            </el-form-item>
          </el-col><!-- /详细地址 -->
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="DetailForm.remarks" disabled></el-input>
            </el-form-item>
          </el-col><!-- /备注 -->
          <el-col :span="24">
            <el-form-item label="联系人" prop="remarks">
              <el-table :data="DetailForm.customer_contacts" height="300" style="width: 100%" border>
                <el-table-column label="联系人ID" prop="id" fixed="left" width="100" align="center" header-align="left">
                </el-table-column>
                <el-table-column label="联系人名称" prop="username">
                </el-table-column>
                <el-table-column label="联系人手机号" prop="phone">
                </el-table-column>
                <el-table-column label="联系人部门" prop="department">
                </el-table-column>
                <el-table-column label="创建时间" prop="created_at">
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col><!-- /联系人 -->
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Detail= false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'region',
  data () {
    return {
      addAdmin: false,
      editAdmin: false,
      UntreatedData: [],
      search: '',
      ocid: '',
      warranty_type: '',
      addform: {
        oc_id: '',
        rtel: '',
        rname: '',
        address: '',
        lng: '',
        lat: '',
        warranty_start_time: '',
        warranty_period: '',
        renewal_type: '',
        renewal_start_time: '',
        renewal_period: '',
        remarks: ''
      },
      editform: {
        id: '',
        oc_id: '',
        rtel: '',
        rname: '',
        address: '',
        lng: '',
        lat: '',
        warranty_start_time: '',
        warranty_period: '',
        renewal_type: '',
        renewal_start_time: '',
        renewal_period: '',
        remarks: ''
      },
      DetailForm: {
        id: '',
        kehu_name: '',
        phone: '',
        number: '',
        detailed_address: '',
        remarks: '',
        customer_contacts: []
      },
      Page: {
        pagesize: 10,
        page: 1,
        maxpage: 1,
        total: 2
      },
      isModify: false,
      Detail: false,
      ocList: [],
      user_role: {},
      warranty_type_list:[
       {
         value: '1',
         text: this.$t('保内')
       },
       {
         value: '2',
         text: this.$t('未续保')
       },
       {
         value: '3',
         text: this.$t('按次续保')
       },
       {
         value: '4',
         text: this.$t('包年续保')
       },
       {
         value: '5',
         text: this.$t('未设置质保时间')
       }
      ]
    }
  },
  created () {
    this.getUserList()
    this.user_role = JSON.parse(window.localStorage.getItem('user_role'))
    this.$AMap()
  },
  mounted () {},
  beforeUpdate () {
    this.ocList = this.$store.state.ocList
  },
  methods: {
    // 打开表单
    dialogForm (item, isModify) {
      this.isModify = isModify
      if (isModify) {
        var key
        for (key in this.editform) {
          if (item.row[key] !== null) this.editform[key] = item.row[key]
        }
        // this.editform.warrStartTime = item.row.warranty_start_time
        // this.editform.warrPeriod = item.row.warranty_period
        this.editAdmin = true
      } else {
        this.addAdmin = true
      }
    },
    // 获取客户区域列表
    getUserList (page, report) {
      let params = {
        page: page?page:this.Page.page,
        name: this.search,
        oc_id: this.ocid,
        warranty_type: this.warranty_type
      }
      /* 导出报表 */
      if (report === 1) {
        params.is_report = report
        this.$common.postExcelFile(params, 'region/lists')
        return
      }
      /* 获取列表 */
      this.$axios.post('region/lists', params).then((res) => {
        const Data = res.data
        // console.log(Data)
        if (Data.code === 0) {
          this.UntreatedData = Data.data.data
          this.Page = {
            pagesize: Data.data.pagesize,
            page: Data.data.page,
            maxpage: Data.data.maxpage,
            total: Data.data.total
          }
        }
      }).catch((error) => console.log(error))
    },
    // 添加
    addMin () {
      var params = {}; var key
      for (key in this.addform) {
        if (this.addform[key] !== '' & this.addform[key] !== null && key !== 'renewal_period') params[key] = this.addform[key]
      }
      if (this.addform.renewal_period > 0) params.renewal_period = this.addform.renewal_period
      this.$axios.post('region/add', params).then((res) => {
        if (res.data.code === 0) {
          this.getUserList()
          this.addAdmin = false
          var that = this
          // 清空表单
          this.$common.resetField('addform', that)
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 修改
    editMin () {
      var params = {}; var key
      for (key in this.editform) {
        if (this.editform[key] !== '' & this.editform[key] !== null && key !== 'renewal_period') params[key] = this.editform[key]
      }
      if (this.editform.renewal_period > 0) params.renewal_period = this.editform.renewal_period
      this.$axios.post('region/edit', params).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.getUserList()
          this.editAdmin = false
          var that = this
          // this.$common.resetField('editform', that)
          this.$refs.resetFields('editform')
          this.$message({
            type: 'success',
            message: res.data.message
          })
        } else {
          this.$message({
            type: 'warning',
            message: res.data.message
          })
          // console.log(res)
        }
      }).catch((error) => console.log(error))
    },
    // 关闭删除弹窗
    closeEdit () {
      this.$refs.editform.resetFields()
      // console.log(this.$refs)
      this.editAdmin = false
    },
    // 删除
    delRole (scope) {
      // console.log(scope)
      let that = this
      this.$MessageBox.confirm(that.$t('此操作将永久删除, 是否继续?'), that.$t('提示'), {
        confirmButtonText: that.$t('确定'),
        cancelButtonText: that.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$axios.post('region/delete', {
          id: scope.row.id
        }).then((res) => {
          // console.log(res)
          if (res.data.code === 0) {
            this.getUserList()
            this.$message({
              type: 'success',
              message: that.$t('删除成功!')
            })
          } else console.log(res)
        }).catch((error) => console.log(error))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: that.$t('已取消删除')
        })
      })
    },
    // 获取详情
    getDetail (scope) {
      // console.log(scope)
      this.$axios.post('customer/detail', {
        id: scope.row.id
      }).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          var data = res.data.data
          for (var key in this.DetailForm) {
            if (key in data) this.DetailForm[key] = data[key]
          }
          this.Detail = true
        } else console.log(res)
      }).catch((error) => console.log(error))
    },
    // pageSize 改变时会触发
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    // currentPage 改变时会触发
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.getUserList(val)
    },
    /* 获取经纬度 */
    getCode () {
      var that = this
      this.$AMap().then((AMap) => {
        // 延迟一秒保证实例出现
        var address = document.getElementById('address')
        var geocoder = new AMap.Geocoder()
        // console.log('geocoder', geocoder)
        geocoder.getLocation(address.value, function (status, result) {
          if (status === 'complete' && result.geocodes.length) {
            var lnglat = result.geocodes[0].location
            if(that.addAdmin){
              that.addform.lng = lnglat.lng
              that.addform.lat = lnglat.lat
            }else if(that.editAdmin){
              that.editform.lng = lnglat.lng
              that.editform.lat = lnglat.lat
            }

            // marker.setPosition(lnglat)
            // map.setFitView(marker)
          } else {
            // log.error('根据地址查询位置失败')
          }
        })
        // console.log(address.value)
      }).catch((e) => {
        console.log(e)
      })
    },
    // 区分行颜色
    tableRowClassName ({ row, rowIndex }) {
      // console.log(row, rowIndex)
      if (row.warranty_type === 2) {
        return 'warning-row'
      }
      return ''
    },
    // 转换质保类型
    getType (type) {
      let List = [
        this.$t('其他'),
        this.$t('质保期内'),
        this.$t('未续保'),
        this.$t('按次续保'),
        this.$t('包年续保')]
      return List[type]
    }
  }
}
</script>

<style scoped>
  .Untreated_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top_title {
    color: #ff9252;
  }

  ::v-deep .el-select,
  ::v-deep .el-date-editor.el-input{
    width: 100% !important;
  }

  .el-input.is-disabled::v-deep .el-input__inner {
    color: #575757;
  }
  ::v-deep .el-table .warning-row {
     background: #ffe4e4;
   }

  ::v-deep .el-table .success-row {
     background: #dbf9d2;
  }
</style>
